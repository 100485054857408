import React from 'react'
import Image from 'gatsby-image'
import {graphql, StaticQuery} from 'gatsby'

import styles from '../components/page.module.css'
import styled from '@emotion/styled'

import BlockContent from '../components/portableText'
import Layout from '../containers/layout'
import SEO from '../components/seo'
import Container from '../components/container'

const PersonWrapper = styled.div`

@media (min-width: 620px) {
  .person--wrapper {
    display: flex;
    margin: 3rem 0;
  }
  .person--first {
    order: 2;
    margin: 0 0 0 3rem;
  }
  .person--second {
    flex: none;
    min-width: 250px;
  }
}

.person--wrapper {
  border-bottom: 2px solid #eee;
  padding-bottom: 2rem;
  margin-bottom: 1rem;
  }
.person--second {
  order: 1;
}
.person--name {
  font-size: 1.6rem;
  margin-bottom: 1rem;
}
.person--role {
  font-style: italic;
  margin-bottom: 1rem;
}
.person--bio {
  margin-bottom: 1rem;
}
`

export default class Index extends React.Component {
  render () {
    return (
      <StaticQuery
        query={graphql`
            query sanityAbout {
              sanityAbout {
                title
                _rawBody
                mainImage {
                  asset {
                    fluid(maxWidth: 1600) {
                      ...GatsbySanityImageFluid
                    }
                  }
                }
                team {
                  _key
                  firstName
                  lastName
                  jobRole
                  bio
                  headshotImage {
                    asset {
                      fluid(maxWidth: 300) {
                        ...GatsbySanityImageFluid
                      }
                    }
                  }
                }
                seoSettings {
                  title
                  description
                  openGraphImage {
                  asset {
                    url
                    }
                  }
                }
              }
            }
          `}
        render={data => (
          <>
            <Layout>
              {data.sanityAbout.seoSettings &&
                data.sanityAbout.seoSettings.title &&
                data.sanityAbout.seoSettings.description &&
                 (
                   <SEO
                     title={data.sanityAbout.seoSettings.title}
                     description={data.sanityAbout.seoSettings.description}
                     ogimage={data.sanityAbout.seoSettings.openGraphImage && data.sanityAbout.seoSettings.openGraphImage.asset ? data.sanityAbout.seoSettings.openGraphImage.asset.url : null}
                   />
                 )}

              <article className={styles.root} >
                {data.sanityAbout.mainImage && data.sanityAbout.mainImage.asset && (
                  <div className={styles.mainImage} >
                    <div className='grunge-edge first' />
                    <Image fluid={data.sanityAbout.mainImage.asset.fluid} alt={data.sanityAbout.title} />
                    <div className='grunge-edge bottom' />
                  </div>
                )}
                <Container>

                  <section className='section'>
                    <div className='container'>
                      <div className='content'>

                        {data.sanityAbout._rawBody && <BlockContent blocks={data.sanityAbout._rawBody} />}

                        <PersonWrapper>
                          {data.sanityAbout.team.map(node => (
                            <div className='person--wrapper' key={node._key}>
                              <div className='person--first'>
                                <div className='person--name'>{node.firstName} {node.lastName}</div>
                                <div className='person--role'>{node.jobRole}</div>
                                <div className='person--bio'>{node.bio}</div>
                              </div>
                              <div className='person--second'>
                                <div className='person--headshot'><Image fluid={node.headshotImage.asset.fluid} alt={node.firstName} /></div>
                              </div>
                            </div>
                          ))}
                        </PersonWrapper>
                      </div>
                    </div>
                  </section>
                </Container>
              </article>
            </Layout>
          </>
        )}
      />
    )
  }
}
